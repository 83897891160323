import { useState } from 'react';

import { createHaapiCaller, findActionKind, getHaapiCallParams } from './haapi-flow';
import { pollRequest } from './helpers';
import { OidcClient } from './oidc-client';
import { Action, CurityConfig } from './types';

type PollingResponse = {
  actions: Action[];
  messages?: { text: string }[];
  properties?: {
    status: 'pending' | 'done' | 'failed';
  };
  links?: {
    href: string;
    rel: string;
    title: string;
    type: string;
  }[];
};

type ExternalDeviceAuth = (
  setQrCode: React.Dispatch<React.SetStateAction<string | undefined>>,
  action?: Action
) => Promise<string>;

const POLLING_OPTIONS = {
  // Curity allows polling for 30 seconds, after which it rejects the authentication request,
  // requiring the initiation of a new authentication process.
  // Therefore, 35 seconds seems like a reasonable value.
  retryCount: 35000,
  intervalValue: 2000,
};

const getPollingQrCode = (pollingResponse: PollingResponse) => pollingResponse.links?.[0]?.href;

export function useBankIdAuth(
  enabled: boolean,
  curityConfig: CurityConfig,
  openLink: (href: string, target: string) => void
) {
  const [cancelAuthProcessAction, setCancelAuthProcessAction] = useState<Action>();

  const [callHaapi] = useState(() => (enabled ? createHaapiCaller(curityConfig) : undefined));
  const [oidcClient] = useState(() => (enabled ? new OidcClient(curityConfig) : undefined));

  if (!callHaapi || !oidcClient) {
    return;
  }

  const cancelAuthProcess = async () => {
    if (cancelAuthProcessAction) {
      await callHaapi(...getHaapiCallParams(cancelAuthProcessAction));
      setCancelAuthProcessAction(undefined);
    }
  };

  const initializeAuth = async (kind: 'poll' | 'login') => {
    const url = await oidcClient.getAuthorizationUrl();
    const authInitialization = await callHaapi<{ actions: Action[] }>(url);
    const methodAction = findActionKind(authInitialization.actions, kind);
    return methodAction;
  };

  const updateCancelActionOnPendigAuth = (pollingResponse: PollingResponse) => {
    const cancelAction = findActionKind(pollingResponse.actions, 'cancel');
    setCancelAuthProcessAction(cancelAction);
  };

  const externalDeviceAuth: ExternalDeviceAuth = async (setQrCode, action) => {
    const pollAction = action || (await initializeAuth('poll'));
    if (!pollAction) {
      throw new Error('No action provided');
    }
    const pollingResponse = await pollRequest(
      () => callHaapi<PollingResponse>(...getHaapiCallParams(pollAction), false),
      response => response.properties?.status !== 'pending',
      response => {
        if (response.properties?.status === 'pending') {
          updateCancelActionOnPendigAuth(response);
          const pollingQrCode = getPollingQrCode(response);
          if (pollingQrCode) {
            setQrCode(pollingQrCode);
          }
        }
      },
      POLLING_OPTIONS
    );
    if (pollingResponse.properties?.status === 'done') {
      const pollingDoneResponse = await callHaapi(
        ...getHaapiCallParams(pollingResponse.actions[0])
      );
      const tokens = await oidcClient.fetchTokens(pollingDoneResponse.properties.code);
      setCancelAuthProcessAction(undefined);
      return tokens.id_token;
    } else {
      const retryCall = await callHaapi(...getHaapiCallParams(pollingResponse.actions[0]));
      const action = findActionKind(retryCall.actions, 'poll');
      return await externalDeviceAuth(setQrCode, action);
    }
  };

  const currentDeviceAuth = async (action?: Action): Promise<string> => {
    const loginAction = action || (await initializeAuth('login'));
    if (!loginAction) {
      throw new Error('No action provided');
    }

    const appLink = loginAction.model?.arguments?.href;
    if (!appLink) {
      throw new Error('No application link provided.');
    }

    openLink(appLink, '_self');
    const pollingResponse = await pollRequest(
      () =>
        callHaapi<PollingResponse>(
          ...getHaapiCallParams(loginAction.model.continueActions[0]),
          false
        ),
      response => response.properties?.status !== 'pending',
      response => {
        if (response.properties?.status === 'pending') {
          updateCancelActionOnPendigAuth(response);
        }
      },
      POLLING_OPTIONS
    );
    if (pollingResponse.properties?.status === 'done') {
      const pollingDoneResponse = await callHaapi(
        ...getHaapiCallParams(pollingResponse.actions[0])
      );
      const tokens = await oidcClient.fetchTokens(pollingDoneResponse.properties.code);
      setCancelAuthProcessAction(undefined);
      return tokens.id_token;
    }

    if (pollingResponse.properties?.status === 'failed') {
      return Promise.reject(pollingResponse.messages?.[0]?.text || 'Authentication failed');
    }
    const retryCall = await callHaapi(...getHaapiCallParams(pollingResponse.actions[0]));
    const retryAction = findActionKind(retryCall.actions, 'login');
    return await currentDeviceAuth(retryAction);
  };

  return { cancelAuthProcess, externalDeviceAuth, currentDeviceAuth };
}
