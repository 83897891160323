import { some, sumBy } from 'lodash';
import { ComponentProps, FC, Fragment } from 'react';

import { DisplayItem, OrderCartPrices, PrivateOrderCartPrices } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';

import { Section, Stack, Text } from '../atoms';
import {
  CartItem,
  CartSummary,
  Expand,
  ExpandProps,
  quantityMultiplicationSign,
} from '../molecules';
import { sprinkles } from '../sprinkles.css';
import * as styles from './cart-items.css';
import { ItemGallery } from './item-gallery';

type Props = Pick<
  ComponentProps<typeof Expand>,
  'onExpandPosthogCapture' | 'onHidePosthogCapture'
> & {
  items: DisplayItem[];
  dataTest?: string;
  appType?: 'widget' | 'page';
  expandProps: Pick<ExpandProps, 'defaultExpanded' | 'expandToggleDisabled' | 'dataTest'>;
  showImages?: boolean;
  showPrices?: boolean;
  showSummary?: boolean;
  currency: string | undefined;
  prices: OrderCartPrices | PrivateOrderCartPrices | undefined;
};

const getTotalPrice = (items: DisplayItem[]) =>
  items.reduce((acc, item) => acc + (item.total_price || 0), 0);

const isValidCart = (items: DisplayItem[]) => items.length > 0 && items.every(item => item.name);

export const CartItemsUI: FC<Props> = ({
  items,
  dataTest,
  appType,
  showImages,
  showPrices,
  showSummary,
  expandProps,
  currency,
  prices,
  ...rest
}) => {
  const formatMessage = useFormatMessage();

  if (!isValidCart(items)) {
    return null;
  }

  const { total: totalPrices, shipping_components: additionalServices } = prices || {};

  const itemCount = items.reduce((acc, item) => acc + (item.quantity || 1), 0);

  const hasAnyImage = items.some(i => i.image);

  const displayImages = !!showImages && hasAnyImage;

  const cartItems = items.map(item => {
    const price = showPrices ? item.total_price : undefined;
    return (
      <CartItem
        key={item.sku}
        name={item.name}
        appType={appType}
        discount={item.discount}
        image={
          displayImages
            ? {
                alt: `Image of ${item.sku || item.name}`,
                fit: 'contain',
                src: item.image,
              }
            : undefined
        }
        price={price}
        currency={currency}
        quantity={item.quantity}
      />
    );
  });

  return (
    <article className={styles.container}>
      <Section dataTest={dataTest}>
        <Expand
          onContentClick={(e, toggle) => {
            if (e.target instanceof HTMLButtonElement) {
              return;
            }
            toggle();
          }}
          {...expandProps}
          expandedChildren={
            <Fragment>
              <Stack paddingRight={24}>
                <Text textStyle={'light1420'} textColor={'darkGrey'}>
                  {formatMessage('CART_ITEMS.HEADER')}
                </Text>
                <Text textStyle={'light1420'} textColor={'black'}>
                  {formatMessage('CART_ITEMS.ITEM_COUNT', { itemCount })}
                </Text>
              </Stack>
              <div data-test="item-name-expanded" className={sprinkles({ paddingTop: 8 })}>
                <div className={styles.cartItems}>{cartItems}</div>
                {showSummary ? (
                  <>
                    <div className={styles.additionalCosts}>
                      {totalPrices?.shipping_price !== undefined && (
                        <CartItem
                          disableQuantity
                          name={formatMessage('CART_ITEMS.DELIVERY')}
                          currency={currency}
                          price={totalPrices.shipping_price}
                          discount={totalPrices.shipping_discount}
                        />
                      )}
                      {!!additionalServices?.length && (
                        <CartItem
                          disableQuantity
                          name={formatMessage('CART_ITEMS.ADDITIONAL_SERVICES')}
                          currency={currency}
                          price={sumBy(additionalServices, 'price')}
                          discount={sumBy(additionalServices, 'discount')}
                        />
                      )}
                    </div>
                    <div className={sprinkles({ paddingTop: 16 })}>
                      <CartSummary total={getTotalPrice(items)} currency={currency} />
                    </div>
                  </>
                ) : null}
              </div>
            </Fragment>
          }
          unexpandedChildren={
            <Stack paddingRight={24}>
              <Text textStyle={'light1420'} textColor={'darkGrey'}>
                {formatMessage('CART_ITEMS.HEADER')}
              </Text>
              <CartItemsOverview items={items} showImages={!!showImages} />
            </Stack>
          }
          {...rest}
        />
      </Section>
    </article>
  );
};

const formatName = (item: DisplayItem, defaultItemName: string) => {
  const name = item.name || defaultItemName;

  if (!item.quantity || item.quantity === 1) {
    return name;
  }

  return `${name} (${quantityMultiplicationSign}${item.quantity})`;
};

type CartItemsOverviewProps = {
  items: DisplayItem[];
  showImages: boolean;
  imagesOnTop?: boolean;
};

export const CartItemsOverview: FC<CartItemsOverviewProps> = ({
  items,
  showImages,
  imagesOnTop,
}) => {
  const formatMessage = useFormatMessage();

  if (!isValidCart(items)) {
    return null;
  }

  const itemCount = sumBy(items, 'quantity');
  const hasAnyImage = some(items, 'image');
  const displayImages = showImages && hasAnyImage;

  const [firstItem] = items as [DisplayItem];
  return (
    <div className={styles.cartItemsOverview({ reverse: imagesOnTop })}>
      <Text textStyle={'light1420'} textColor={'black'}>
        {formatMessage('CART_ITEMS.ITEM_COUNT_WITH_FIRST_ITEM_NAME', {
          itemName: formatName(firstItem, formatMessage('CART_ITEMS.UNNAMED_ITEM')),
          itemCount: itemCount - (firstItem.quantity ?? 1),
        })}
      </Text>
      {displayImages && <ItemGallery items={items.filter(e => e.image)} />}
    </div>
  );
};
