import { FetchLike, createHaapiFetch } from '@curity/identityserver-haapi-web-driver';

import { Action, CurityConfig } from './types';

type HaapiCaller = <T = any>(
  url: URL,
  method?: string,
  data?: URLSearchParams,
  followRedirects?: boolean
) => Promise<T>;

const getHaapiCallData = (fields?: Action['model']['fields']) => {
  if (!fields) {
    return;
  }
  return new URLSearchParams(fields.map(field => [field.name, field.value]));
};

export const getHaapiCallParams = (action?: Action) => {
  if (!action) {
    throw new Error('No action provided');
  }
  return [
    new URL(action.model.href),
    action.model.method,
    getHaapiCallData(action.model.fields),
  ] as const;
};

export const findActionKind = (actions: Action[], kind: string) =>
  actions.find((action: Action) => action.kind === kind);

const createBaseHaapiUrl = (url: URL, method: string, data: URLSearchParams | undefined) => {
  if (method !== 'GET' || !data) {
    return url.toString();
  }
  const finalUrl = url;
  data.forEach((value, key) => {
    finalUrl.searchParams.set(key, value);
  });
  return finalUrl.toString();
};

export const createHaapiCaller = (config: CurityConfig): HaapiCaller => {
  const haapiFetch = createHaapiFetch({
    clientId: config.clientId,
    tokenEndpoint: config.tokenEndpoint,
  });
  haapiFetch.init();

  const callHaapiBase: HaapiCaller = async (url, method = 'GET', data) => {
    const init: Parameters<FetchLike>[1] = { method, body: method !== 'get' ? data : undefined };
    const finalUrl = createBaseHaapiUrl(url, method, data);
    const response = await haapiFetch(finalUrl, init);
    const haapiResponse = response.json();
    return haapiResponse;
  };

  const callHaapi: HaapiCaller = async (url, method, data, followRedirects = true) => {
    const response = await callHaapiBase(url, method, data);
    const action = response.actions?.[0];
    if (action?.template === 'form' && action?.kind === 'redirect' && followRedirects) {
      return callHaapi(
        action.model.href,
        action.model.method,
        getHaapiCallData(action.model.fields)
      );
    }
    return response;
  };

  return callHaapi;
};
