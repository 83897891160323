import { keyBy } from 'lodash';
import { FC, PropsWithChildren, createContext, useContext } from 'react';

import {
  DirectionType,
  GetPrivateTrackingDataResponse,
  GetTrackingDataResponse,
  PageBranding,
  ProgressItem,
  SectionsConfig,
  Step,
  WidgetBranding,
} from '../../../generated/types';
import { getCurrentStatus, getParcels } from '../../selectors';
import { evaluateObjectDeep } from './evaluate-expression';
import { sanitizeBranding } from './sanitize-branding';

export type Configuration = {
  sections: SectionsConfig;
  translationKey: string;
};

export type Branding = WidgetBranding | PageBranding;

const context = createContext<Branding | null>(null);

type ExpressionStep = {
  name: Step;
  time: string | null;
  estimated_time: {
    start: string | null;
    end: string | null;
  };
};
export type ExpressionContextData = {
  locale?: string | null;
  parcel?: {
    direction: DirectionType;
    step: ExpressionStep;
    steps: Record<Step, ExpressionStep>;
    destination: {
      address: {
        country_code: string | null;
        region_code: string | null;
      };
    };
    carrier: {
      name: string | null;
      product_ref: string | null;
      product_id: string | null;
    };
  };
};

const prepareDate = (value: string | undefined) => {
  if (!value) {
    return null;
  }

  const date = new Date(value);

  // The dates in the expressions are defined without seconds/ms fraction.
  // Removing possible seconds to make the comparison operators work correctly.
  date.setUTCSeconds(0, 0);

  return date.toISOString();
};

const toExpressionStep = (item: ProgressItem | undefined): ExpressionStep => ({
  name: item?.step ?? 'STEP_UNKNOWN',
  time: prepareDate(item?.time),
  estimated_time: {
    start: prepareDate(item?.estimated_time?.start),
    end: prepareDate(item?.estimated_time?.end),
  },
});
export const trackingDataToExpressionContext = ({
  data,
  locale,
  parcelIndex,
}: {
  data: GetPrivateTrackingDataResponse | GetTrackingDataResponse;
  locale: string;
  parcelIndex?: number;
}): ExpressionContextData => {
  const parcels = getParcels(data);

  if ((typeof parcelIndex === 'undefined' && parcels.length > 1) || !parcels.length) {
    return {
      locale,
    };
  }

  const parcel = parcels[parcelIndex || 0]!;

  const currentStep = getCurrentStatus(parcel?.progress ?? []);

  const address = parcel?.location?.address;
  return {
    parcel: {
      step: toExpressionStep(currentStep),
      steps: keyBy(parcel?.progress?.map(toExpressionStep) ?? [], 'name') as Record<
        Step,
        ExpressionStep
      >,

      destination: {
        address: {
          country_code: address?.country ?? null,
          // The region code name makes sense in context of the country only.
          // eg. WA - Washington (US) is different from WA - Western Australia (AU).
          region_code:
            address?.country && address?.region ? `${address.country}-${address.region}` : null,
        },
      },
      direction: parcel.direction_type ?? 'UNSPECIFIED',
      carrier: {
        name: parcel?.carrier ?? null,
        product_ref: parcel?.carrier_product_ref ?? null,
        product_id: parcel.carrier_product_id ?? null,
      },
    },
    locale,
  };
};

type ConfigurationProviderProps<T extends Branding> = {
  config: T;
  expressionContextData?: ExpressionContextData;
};

export const ConfigurationProvider: FC<PropsWithChildren<ConfigurationProviderProps<Branding>>> = ({
  config,
  expressionContextData = {},
  children,
}) => (
  <context.Provider value={config}>
    <ExpressionContextProvider value={expressionContextData}>{children}</ExpressionContextProvider>
  </context.Provider>
);

const useConfiguration = () => {
  const value = useContext(context);
  const rules = useContext(expressionContext);

  if (!value) {
    throw new Error('Missing value for the configuration context');
  }

  return sanitizeBranding(evaluateObjectDeep(value, rules));
};

const expressionContext = createContext<ExpressionContextData>({});
export const ExpressionContextProvider = expressionContext.Provider;

// it does not create a different context, but makes it more explicit
export const getConfigurationContext = <T extends Branding>(): {
  ConfigurationProvider: typeof ConfigurationProvider;
  useConfiguration: () => T;
} => ({
  ConfigurationProvider: ConfigurationProvider,
  useConfiguration: useConfiguration as () => T,
});
